.Team {
  margin: 5%;
  flex: 0.25;
}

.Member {
  display: flex;
  text-align: center;
  justify-content: center;
  column-gap: 10px;
  row-gap: 50px;
  flex-wrap: wrap;
  flex: 1;
}

.OurTeam {
  margin: 10% 5%;
  display: flex;
}

.seperator {
  width: 6px;
  background-color: red;
  border-radius: 50px;
  margin-right: 10%;
}

.Members {
  margin: 5px;
  /* border: 2px solid red; */
  width: 30%;
}
.Members h2 {
  font-size: 15px;
  font-weight: bold;
}

.Members p {
  font-weight: 400;
  color: black;
  font-family: cursive;
}

.Team p {
  font-size: 60px;
  font-weight: bold;
  color: black;
}

.avatar {
  border: 5px solid black;
}
.social-icons {
  visibility: hidden;
}
.Members:hover .social-icons {
  visibility: visible;
  color: white;
  opacity: 0.8;
  transition: 0.5s ease;
  transform: translate(0%, -270%);
}
.social-icons a {
  color: white;
}
.Members:hover {
  opacity: 1;
  transition: 0.4s;
  background: rgb(0 0 0 / 70%);
  border-radius: 16%;
}
.Members:hover .avatar {
  opacity: 0.5;
}
.Members:hover h2 {
  opacity: 1;
  transition: 0.5s ease;
  color: white;
  font-family: sans-serif;
  transform: translate(0%, -150%);
}
.Members:hover p {
  opacity: 1;
  transition: 0.5s ease;
  color: white;

  transform: translate(2%, 0%);
}

@media only screen and (max-width: 768px) {
  .OurTeam {
    display: block;
  }
  .Team P {
    font-size: 2em;
  }
  .seperator {
    height: 6px;
    margin-right: auto;
    width: auto;
  }
  .Members {
    width: 100%;
  }
}

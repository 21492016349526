#footer {
  background: #212529;
  padding: 0 0 30px 0;
  color: #eee;
  font-size: 14px;
}
#footer .footer-top {
  background: #212529;
  padding: 30px 0 30px 0;
}

.footer-contact {
  margin: 2%;
}
.footer-links {
  margin: 2%;
}
.footer-info {
  margin: 2%;
}
.social-links {
  margin: 2%;
}
.social-links img {
  padding: 5px;
}
.row {
  display: flex;
  justify-content: space-around;
  margin-right: -15px;
  margin-left: -15px;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid red;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #eee;
}
.containerL {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 8px;
  color: #ddd;
}
#footer .footer-top .footer-links a:hover {
  color: #eee;
}
#footer .footer-top .footer-links a {
  color: #eee;
}
/* a:hover {
  color: #18d26e;
} */

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top h4::after {
  background: red;
  width: 60px;
}
#footer .footer-top h4::before {
  right: 0;
  background: #555;
}
#footer .footer-top h4::before,
#footer .footer-top h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .footer-top .footer-links ul li a {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.footer-info img {
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .social-links img {
    width: 30px;
    height: 30px;
  }
  .footer-info img {
    width: 40%;
  }
}

.Blog {
  text-align: center;
  margin-bottom: 0%;
}

.social {
  color: black;
}

.feeds {
  display: flex;
  justify-content: center;
}

.blog-head {
  display: flex;
  margin: 2%;
  justify-content: center;
}

#dotted {
  margin: 6% 6%;
  border-top: 3px dotted #261919;
}

#dotted1 {
  margin: 6% 6%;
  border-top: 3px dotted #261919;
}
.grid-container {
  display: block;
  grid-template-columns: auto auto;

  padding: 10px;
}
.grid-item {
  background-color: #cdc5c5cc;
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  margin: 50px;
  text-align: center;
}

.navbar-dark .navbar-nav .nav-link {
  color: red;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: cursive;
  font-size: 17px;
}

.bg-img {
  min-height: 40rem;
  background-color: #434043;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.Homeimg {
  position: absolute;
  width: 400px;
  height: 340px;
  top: 18%;
  right: 37%;
}
.logo {
  position: absolute;
  font-size: 50px;
  font-weight: bold;
  left: 10px;
}
.logo > span {
  color: #e3332c;
}
.navbar-nav {
  margin-left: auto;
}
.slogan {
  position: absolute;
  color: white;
  bottom: 7%;
  font-size: 34px;
  left: 37%;
}
.slogan pre {
  font-style: italic;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(0 0 0 / 94%);
}
@media only screen and (max-width: 992px) {
  .navbar-nav {
    margin-left: 0;
  }
  .Homeimg {
    display: none;
  }
  .bg-img {
    min-height: 10%;
  }
  .slogan {
    display: none;
  }
  .logo {
    left: 23%;
    bottom: -10%;
  }
}

/* hambirger */
.navbar-dark .navbar-toggler-icon {
  background-image: url("https://cdn-icons-png.flaticon.com/512/151/151917.png");
}

.affordable {
  text-align: center;
  margin-bottom: 10%;
}

#work1 {
  font-size: 29px;
  font-weight: bold;
  color: black;
}
.home_readmore {
  text-align: center;
  margin-top: 3%;
}
.home_readmore a {
  color: black;
  text-decoration: none;
  font-size: 20px;
  font-family: sans-serif;
}
#video {
  border: 2px solid black;
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 20%);
  border-radius: 30px;
}
.homeAutomation1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 25px solid #d8382a;
  background-color: #d8382a;
  border-radius: 40px;
  outline-offset: -20px;
  outline: 5px solid #e3766d;
}
.what1 {
  flex: 0.5;
  margin: 0 5% 0 5%;
}

#mb {
  margin-top: 15%;
  display: flex;
  justify-content: center;
}
#sample {
  color: white;
}

@media only screen and (max-width: 786px) {
  .homeAutomation1 {
    display: block;

    height: auto;
    text-align: center;
    width: 100%;
  }
  .what1 {
    margin: 0px 0px 0px 0px;
  }
  .video {
    margin-bottom: 50px;
  }
}

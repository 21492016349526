.Let_me p {
  font-size: 60px;
  font-weight: bold;

  text-align: center;
  color: black;
}

.Me {
  font-weight: 600;
  margin: 5% 0%;
  flex: 1 1;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.Intro {
  margin: 10% 5%;
  display: flex;
}
.Let_me {
  margin: 5%;
  flex: 0.25 1;
}
.Me p {
  color: black;
}
@media only screen and (max-width: 768px) {
  .Intro {
    display: block;
  }
  .Let_me p {
    font-size: 2em;
  }
  .Me p {
    font-size: 16px;
    text-align: center;
    color: black;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: red;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: cursive;
  font-size: 17px;
}

.bg-img {
  min-height: 40rem;
  background-color: #434043;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.Homeimg {
  position: absolute;
  width: 400px;
  height: 340px;
  top: 18%;
  right: 37%;
}
.logo {
  position: absolute;
  font-size: 50px;
  font-weight: bold;
  left: 10px;
}
.logo > span {
  color: #e3332c;
}
.navbar-nav {
  margin-left: auto;
}
.slogan {
  position: absolute;
  color: white;
  bottom: 7%;
  font-size: 34px;
  left: 37%;
}
.slogan pre {
  font-style: italic;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(0 0 0 / 94%);
}
@media only screen and (max-width: 992px) {
  .navbar-nav {
    margin-left: 0;
  }
  .Homeimg {
    display: none;
  }
  .bg-img {
    min-height: 10%;
  }
  .slogan {
    display: none;
  }
  .logo {
    left: 23%;
    bottom: -10%;
  }
}

/* hambirger */
.navbar-dark .navbar-toggler-icon {
  background-image: url("https://cdn-icons-png.flaticon.com/512/151/151917.png");
}

.title-vision {
  color: white;
  font-size: 3.5em;
  font-weight: bolder;
}

.OurVision {
  padding: 5%;
  min-height: 35em;
}
.VMP {
  display: flex;
  justify-content: space-around;

  color: white;
}

.VMP p {
  border: 2px solid black;
  margin: 0% 10%;
  padding: 5%;
  height: 100%;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.Vision,
.Motivation,
.Plan {
  text-align: center;
  width: 34%;
}

.heading {
  display: flex;
  justify-content: space-around;
  color: white;
  background-color: black;
}
.small_scr {
  display: none;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: #212529;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button {
  color: white;
  background-color: #212529;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.VMP p:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.VMP p li {
  list-style-type: none;
  color: cyan;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .title-vision {
    font-size: 1.5em;
  }
  .big_scr {
    display: none;
  }
  .small_scr {
    display: block;
  }
}

.Team {
  margin: 5%;
  flex: 0.25 1;
}

.Member {
  display: flex;
  text-align: center;
  justify-content: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 50px;
  row-gap: 50px;
  flex-wrap: wrap;
  flex: 1 1;
}

.OurTeam {
  margin: 10% 5%;
  display: flex;
}

.seperator {
  width: 6px;
  background-color: red;
  border-radius: 50px;
  margin-right: 10%;
}

.Members {
  margin: 5px;
  /* border: 2px solid red; */
  width: 30%;
}
.Members h2 {
  font-size: 15px;
  font-weight: bold;
}

.Members p {
  font-weight: 400;
  color: black;
  font-family: cursive;
}

.Team p {
  font-size: 60px;
  font-weight: bold;
  color: black;
}

.avatar {
  border: 5px solid black;
}
.social-icons {
  visibility: hidden;
}
.Members:hover .social-icons {
  visibility: visible;
  color: white;
  opacity: 0.8;
  transition: 0.5s ease;
  -webkit-transform: translate(0%, -270%);
          transform: translate(0%, -270%);
}
.social-icons a {
  color: white;
}
.Members:hover {
  opacity: 1;
  transition: 0.4s;
  background: rgb(0 0 0 / 70%);
  border-radius: 16%;
}
.Members:hover .avatar {
  opacity: 0.5;
}
.Members:hover h2 {
  opacity: 1;
  transition: 0.5s ease;
  color: white;
  font-family: sans-serif;
  -webkit-transform: translate(0%, -150%);
          transform: translate(0%, -150%);
}
.Members:hover p {
  opacity: 1;
  transition: 0.5s ease;
  color: white;

  -webkit-transform: translate(2%, 0%);

          transform: translate(2%, 0%);
}

@media only screen and (max-width: 768px) {
  .OurTeam {
    display: block;
  }
  .Team P {
    font-size: 2em;
  }
  .seperator {
    height: 6px;
    margin-right: auto;
    width: auto;
  }
  .Members {
    width: 100%;
  }
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.title {
  margin-right: 10%;
  font-size: 1.5em;
  color: #052663;
  text-align: center;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Healthcare {
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 25px solid #56d0eb;
  background-color: #56d0eb;
  border-radius: 40px;
  outline-offset: -20px;
  outline: 5px solid #7fdbef;
}

.what1 {
  height: auto;
  margin: 0% 7% 3% 10%;
}
#question1 {
  font-size: 25px;
  color: black;
}
#ans {
  font-size: 12px;
  color: white;
}
.smart_house {
  margin: 7% 0% 10% 0%;
}

@media only screen and (max-width: 900px) {
  .container {
    display: block;
    text-align: center;
    padding: 50px;
    width: 100%;
    padding: 50px;
  }
  .homeAutomation {
    display: block;
    text-align: center;
    padding: 50px;
    width: 100%;
  }
  .title {
    margin-right: 0;
    padding: 40px;
  }

  .smart_house {
    display: flex;
    justify-content: center;
  }
  .smart_house img {
    width: 100%;
  }
}

.affordable {
  text-align: center;
  margin-bottom: 10%;
}

#work {
  font-size: 29px;
  font-weight: bold;
  color: black;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  padding: 10px;
  background-color: #c4c4c4;
  color: black;
  height: 250px;
  border-radius: 30px;
  box-shadow: 11px 10px 9px -3px rgb(0 0 0 / 25%);
}
.homeAutomation1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 25px solid #56d0eb;
  background-color: #56d0eb;
  border-radius: 40px;
  outline-offset: -20px;
  outline: 5px solid #7fdbef;
}
.what {
  flex: 0.5 1;
  margin: 0 5% 0 5%;
}

#mb {
  margin-top: 15%;
  display: flex;
  justify-content: center;
}
#sample {
  color: white;
}
.affordable p {
  color: black;
}

.health_readmore a {
  text-decoration: none;
}
.health_readmore {
  text-align: center;
  margin-top: 3%;
}
@media only screen and (max-width: 900px) {
  .Healthcare {
    display: block;
    height: 100%;
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  .what {
    margin: 0px 0px 0px 0px;
  }
  .video {
    margin-bottom: 50px;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 30px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.HealthProduct {
  display: flex;
  flex-direction: column;
}
.healthImg {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
}
.healthImg img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  border: 25px solid #efeeee;
  border-radius: 40px;
  margin: 2%;
  outline-offset: -29px;
  outline: 5px solid #d1d1d1;
  box-shadow: 11px 10px 9px -3px rgba(0, 0, 0, 25%) inset;
}
#title2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4%;
}
@media only screen and (max-width: 768px) {
  .ourProduct {
    display: block;
    text-align: center;
    width: 100%;
  }
  .healthImg img {
    width: 100%;
  }
  .healthImg {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: auto;
  }
  .healthImg img {
    border: 15px solid #efeeee;
    border-radius: 27px;
    margin: 2%;
    outline-offset: -15px;
    outline: 5px solid #d1d1d1;
  }
}

.affordable {
  text-align: center;
  margin-bottom: 10%;
}

#work1 {
  font-size: 29px;
  font-weight: bold;
  color: black;
}
.home_readmore {
  text-align: center;
  margin-top: 3%;
}
.home_readmore a {
  color: black;
  text-decoration: none;
  font-size: 20px;
  font-family: sans-serif;
}
#video {
  border: 2px solid black;
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 20%);
  border-radius: 30px;
}
.homeAutomation1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 25px solid #d8382a;
  background-color: #d8382a;
  border-radius: 40px;
  outline-offset: -20px;
  outline: 5px solid #e3766d;
}
.what1 {
  flex: 0.5 1;
  margin: 0 5% 0 5%;
}

#mb {
  margin-top: 15%;
  display: flex;
  justify-content: center;
}
#sample {
  color: white;
}

@media only screen and (max-width: 786px) {
  .homeAutomation1 {
    display: block;

    height: auto;
    text-align: center;
    width: 100%;
  }
  .what1 {
    margin: 0px 0px 0px 0px;
  }
  .video {
    margin-bottom: 50px;
  }
}

.ourProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 25px solid #efeeee;
  border-radius: 40px;
  margin: 2%;
  outline-offset: -29px;
  outline: 5px solid #d1d1d1;
  box-shadow: 11px 10px 9px -3px rgba(0, 0, 0, 25%) inset;
}

.teconicoHome h2 {
  color: red;
}
#title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}
.teconicoHome p {
  color: #553355;
  font-weight: 500;
  font-size: 18px;
}

.teconicoHome {
  margin: 0% 10% 0% 6%;
}

.productImg {
  display: flex;
  justify-content: center;
  margin-right: 8%;
}
@media only screen and (max-width: 768px) {
  .ourProduct {
    display: block;
    text-align: center;
    width: 100%;
  }
  .teconicoHome {
    margin: 0px 0px 0px 0px;
  }
  .productImg {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: auto;
  }
  .productImg img {
    width: 100%;
  }
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.title {
  margin-right: 10%;
  font-size: 1.5em;
  color: #052663;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeAutomation {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 25px solid #d8382a;
  background-color: #d8382a;
  border-radius: 40px;
  outline-offset: -20px;
  outline: 5px solid #e3766d;
}
.what {
  height: auto;
  margin: 2% 5%;
}
#question {
  font-size: 30px;
}
#ans {
  font-size: 15px;
}
.smart_house {
  margin: 7% 0% 10% 0%;
}

@media only screen and (max-width: 800px) {
  .container {
    display: block;
    text-align: center;
    padding: 0px;
    width: 100%;
  }
  .homeAutomation {
    display: block;
    text-align: center;
    width: 100%;
  }
  .title {
    margin-right: 0;
    padding: 40px;
  }

  .smart_house {
    display: flex;
    justify-content: center;
  }
}

.contact_section {
  padding: 4% 8%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url(/static/media/image.d61c91c4.png);
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}
.contact_us {
  text-align: center;
}

.contact_info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5% 0;
}

.contact_info_right {
  background: white;
  padding: 5%;
}

.icons {
  display: flex;
}
.form-control {
  border: none;
  margin: 15px 0;
  border-bottom: 1px solid black;
}
.form-control:focus {
  box-shadow: none;
}

.form-group {
  margin: 15px 0;
}
.icons div {
  margin-left: 10px;
}

/* .contact_us p {
    display: flex;
    justify-content: center;
    margin: 0 5%;
  } */
@media only screen and (max-width: 768px) {
  .contact_info {
    flex-direction: column;
  }
  .contact_info_right {
    width: 100%;
  }
  .contact_info_left {
    display: none;
  }
  .contact_section {
    background-size: cover;
    background-position: inherit;
  }
}

.DemoProduct {
  display: flex;
  flex-direction: column;
}
.demoImg {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
}
.demoImg img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  border: 25px solid #efeeee;
  border-radius: 40px;
  margin: 2%;
  outline-offset: -29px;
  outline: 5px solid #d1d1d1;
  box-shadow: 11px 10px 9px -3px rgba(0, 0, 0, 25%) inset;
}
#title2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3%;
}
@media only screen and (max-width: 768px) {
  .DemoProduct {
    display: block;
    text-align: center;
    width: 100%;
  }
  .demoImg img {
    width: 100%;
    height: auto;
  }
  .demoImg {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: auto;
  }
  .demoImg img {
    border: 15px solid #efeeee;
    border-radius: 27px;
    margin: 2%;
    outline-offset: -15px;
    outline: 5px solid #d1d1d1;
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 100%;
}

/* .container img {
  width: 100%;
  height: auto;
} */

.next {
  position: absolute;
  top: 68%;
  left: 92%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;

  padding: 40px 40px;
  border: transparent;
  cursor: pointer;
}

.prev {
  position: absolute;
  top: 68%;
  left: 84%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;

  padding: 40px 40px;
  border: transparent;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .prev {
    padding: 7px 9px;
  }
  .next {
    padding: 7px 9px;
  }
}

.Blog {
  text-align: center;
  margin-bottom: 0%;
}

.social {
  color: black;
}

.feeds {
  display: flex;
  justify-content: center;
}

.blog-head {
  display: flex;
  margin: 2%;
  justify-content: center;
}

#dotted {
  margin: 6% 6%;
  border-top: 3px dotted #261919;
}

#dotted1 {
  margin: 6% 6%;
  border-top: 3px dotted #261919;
}
.grid-container {
  display: block;
  grid-template-columns: auto auto;

  padding: 10px;
}
.grid-item {
  background-color: #cdc5c5cc;
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  margin: 50px;
  text-align: center;
}

#footer {
  background: #212529;
  padding: 0 0 30px 0;
  color: #eee;
  font-size: 14px;
}
#footer .footer-top {
  background: #212529;
  padding: 30px 0 30px 0;
}

.footer-contact {
  margin: 2%;
}
.footer-links {
  margin: 2%;
}
.footer-info {
  margin: 2%;
}
.social-links {
  margin: 2%;
}
.social-links img {
  padding: 5px;
}
.row {
  display: flex;
  justify-content: space-around;
  margin-right: -15px;
  margin-left: -15px;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid red;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #eee;
}
.containerL {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 8px;
  color: #ddd;
}
#footer .footer-top .footer-links a:hover {
  color: #eee;
}
#footer .footer-top .footer-links a {
  color: #eee;
}
/* a:hover {
  color: #18d26e;
} */

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top h4::after {
  background: red;
  width: 60px;
}
#footer .footer-top h4::before {
  right: 0;
  background: #555;
}
#footer .footer-top h4::before,
#footer .footer-top h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .footer-top .footer-links ul li a {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.footer-info img {
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .social-links img {
    width: 30px;
    height: 30px;
  }
  .footer-info img {
    width: 40%;
  }
}

.main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.main-container img{
    width: 40%;
    height: 40%;
    margin-bottom: 10%;
}
.main-container button{
    width: 60%;
    margin-top: 5%;
}
.verify-button{
    margin-inline:5%;
    margin-block:5%;
}
.input{
    margin-inline:5px;
    font-size: 2.8rem;
    height: 2% ;
    width: 2% ;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    background-color: transparent;
    /* border-color: red; */
    outline: none;
}
.incorrect{
    margin-top: 2%;
    color: red;
}
.input-field{
    display: flex;
    justify-content: center;
    height: 100%;
    margin-top: 5%;
}

.input:focus{
    border-color: rgb(16, 164, 209);
    border-width: 3px;
}
@media only screen and (max-width: 768px) {
    .main-container{
        height: 91vh;
    }
    .input{
        font-size: 2rem;
    }
    .verify-button{
        margin-inline:0;
    }
    .card{
        margin-inline:5%;
    }
}


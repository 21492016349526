.Let_me p {
  font-size: 60px;
  font-weight: bold;

  text-align: center;
  color: black;
}

.Me {
  font-weight: 600;
  margin: 5% 0%;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.Intro {
  margin: 10% 5%;
  display: flex;
}
.Let_me {
  margin: 5%;
  flex: 0.25;
}
.Me p {
  color: black;
}
@media only screen and (max-width: 768px) {
  .Intro {
    display: block;
  }
  .Let_me p {
    font-size: 2em;
  }
  .Me p {
    font-size: 16px;
    text-align: center;
    color: black;
  }
}

.title-vision {
  color: white;
  font-size: 3.5em;
  font-weight: bolder;
}

.OurVision {
  padding: 5%;
  min-height: 35em;
}
.VMP {
  display: flex;
  justify-content: space-around;

  color: white;
}

.VMP p {
  border: 2px solid black;
  margin: 0% 10%;
  padding: 5%;
  height: 100%;
  transition: transform 0.5s;
}
.Vision,
.Motivation,
.Plan {
  text-align: center;
  width: 34%;
}

.heading {
  display: flex;
  justify-content: space-around;
  color: white;
  background-color: black;
}
.small_scr {
  display: none;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: #212529;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button {
  color: white;
  background-color: #212529;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.VMP p:hover {
  transform: scale(1.05);
}
.VMP p li {
  list-style-type: none;
  color: cyan;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .title-vision {
    font-size: 1.5em;
  }
  .big_scr {
    display: none;
  }
  .small_scr {
    display: block;
  }
}

.HealthProduct {
  display: flex;
  flex-direction: column;
}
.healthImg {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
}
.healthImg img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  border: 25px solid #efeeee;
  border-radius: 40px;
  margin: 2%;
  outline-offset: -29px;
  outline: 5px solid #d1d1d1;
  box-shadow: 11px 10px 9px -3px rgba(0, 0, 0, 25%) inset;
}
#title2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4%;
}
@media only screen and (max-width: 768px) {
  .ourProduct {
    display: block;
    text-align: center;
    width: 100%;
  }
  .healthImg img {
    width: 100%;
  }
  .healthImg {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: auto;
  }
  .healthImg img {
    border: 15px solid #efeeee;
    border-radius: 27px;
    margin: 2%;
    outline-offset: -15px;
    outline: 5px solid #d1d1d1;
  }
}
